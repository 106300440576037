import Header from './Components/Header';
import SectionHeader from './Components/SectionHeader';
import './Homepage.css';
import ProjectsGrid from './Components/ProjectsGrid';
import CompetitionsGrid from './Components/CompetitionsGrid';
import { NAV_WORK_LABEL } from './Strings/GlobalStrings';
import * as homepageStrings from './Strings/HomepageStrings.js';
import Footer from './Components/Footer';
import Tab from './Components/Tab.js';
import Testimonial from './Components/Testimonial.js';
import photoCraig from './Images/Rocket/Testimonials/photo_craig.jpeg';
import photoErin from './Images/Rocket/Testimonials/photo_erin.jpeg';
import photoTyler from './Images/Rocket/Testimonials/photo_tyler.jpeg';
import photoAlee from './Images/Rocket/Testimonials/photo_alee.jpeg';
import photoSandeep from './Images/Testimonials/photo_sandeep.jpeg';
import photoMark from './Images/Testimonials/photo_mark.png';

const Homepage = () => {

  return (
    <div>

      <Header
        selected_link_label={NAV_WORK_LABEL}
      />

      <div className='fade-in welcome-container'>

        <div className='container my-0 mx-auto'>

        <div className='flex flex-col gap-8 px-4 md:px-16'>

        <div className='w-full md:w-7/12 flex flex-col gap-0'>

          <h1 className='h1 indigo'>{homepageStrings.HOMEPAGE_NAME}</h1>



          <h3 className='h3 dark'>{homepageStrings.HOMEPAGE_ROLE}</h3>


            </div>
            {/* <div className='w-full grid grid-cols-1 gap-4 md:grid-cols-3'>
              <div className='col-span-1'>
                <div className='h3 dark'>💼</div>
                <div className='h4 dark'>3 years experience</div>
              </div>
              <div className='col-span-1'>
                <div className='h3 dark'>🎓</div>
                <div className='h4 dark'>Master's in HCI</div>
              </div>
              <div className='col-span-1'>
                <div className='h3 dark'>👨‍💻</div>
                <div className='h4 dark'>Developer turned designer</div>
              </div>
            </div> */}
          </div>

</div>


        <div className="scroll-container">
          <div className="scroll">
            <div className="right-to-left">
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS1}</p>
              <p className='body'>{homepageStrings.HOMEPAGE_STATUS2}</p>
            </div>

          </div>
        </div>

      </div>

      <div className='fade-in homepage-container'>

        <div className='container my-0 mx-auto homepage-content-container'>

          <div className='featured-section'>
            <div className='w-full flex flex-col gap-8 md:flex-row md:items-center md:justify-between'>
            <SectionHeader
              title={homepageStrings.FEATURED_SECTION_HEADING}
              type='short'
            />

            <div className='flex gap-4 items-center'>
              <Tab
                label={'Product design'}
                path={'/'}
                active={true}
              />
              <Tab
                label={'Design system'}
                path={'/design-system'}
                active={false}
              />
            </div>
            </div>
            <ProjectsGrid />
          </div>

          <div className='featured-section'>
            <SectionHeader
              title={homepageStrings.COMPETITION_SECTION_HEADING}
              type='short'
            />

            <CompetitionsGrid />
          </div>

          <div className='w-full featured-section'>
            <SectionHeader
              title={homepageStrings.TESTIMONIAL_SECTION_HEADING}
              type='short'
            />
            <div className='w-full'>
              <div className='flex gap-4 md:gap-8 overflow-x-auto scroll-snap-x snap-mandatory scroll-smooth'>
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE6}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE6}
                  name={homepageStrings.TESTIMONIAL_NAME6}
                  position={homepageStrings.TESTIMONIAL_POSITION6}
                  image={photoMark}
                />
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE1}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE1}
                  name={homepageStrings.TESTIMONIAL_NAME1}
                  position={homepageStrings.TESTIMONIAL_POSITION1}
                  image={photoCraig}
                />
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE5}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE5}
                  name={homepageStrings.TESTIMONIAL_NAME5}
                  position={homepageStrings.TESTIMONIAL_POSITION5}
                  image={photoSandeep}
                />
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE2}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE2}
                  name={homepageStrings.TESTIMONIAL_NAME2}
                  position={homepageStrings.TESTIMONIAL_POSITION2}
                  image={photoErin}
                />
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE3}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE3}
                  name={homepageStrings.TESTIMONIAL_NAME3}
                  position={homepageStrings.TESTIMONIAL_POSITION3}
                  image={photoTyler}
                />
                <Testimonial
                  title={homepageStrings.TESTIMONIAL_TITLE4}
                  subtitle={homepageStrings.TESTIMONIAL_SUBTITLE4}
                  name={homepageStrings.TESTIMONIAL_NAME4}
                  position={homepageStrings.TESTIMONIAL_POSITION4}
                  image={photoAlee}
                />
              </div>
            </div>
          </div>
        




            
            
          


        </div>

        <Footer />

      </div>

    </div>
  );
}

export default Homepage;
